export const environment = {
  production: true,
  envName: 'qa',
  autoClub: 'W2C',
  addressify: {
    baseUrl: 'https://api.addressify.com.au',
  },
  baseAPIUrl:
    'https://ca-qa-tmma-ltx-qj-api.icymeadow-5a31b099.australiaeast.azurecontainerapps.io/api/',
  DMSAPI: {
    baseUrl: 'https://dmsapiservices.azurewebsites.net/',
  },
  PASUrl: 'https://pasqa.tmaainsurance.com',
};
