<section class="header solidheader">
  <div id="div_theme" class="themeInfo theme-info-wrapper" *ngIf="isPASUser">
    <div class="container">
      <div class="row justify-content-center align-items-center">
        <div class="col-sm-9 mobile-width-pas-text">
          <div class="themeInfoText">
            Tokio Marine Travel Insurance Policy Administration System
          </div>
        </div>
        <div class="col-sm-3 mobile-width-pas-button">
          <div class="row justify-content-center">
            <div class="col-md-12">
              <div class="text-md-end">
                <span class="number text-md-end agentName">
                  {{ agentName }}
                  <a id="PasUrl" (click)="clearSession()"
                    >← BACK TO PAS</a
                  ></span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    class="container-fluid borderonscroll"
    [ngClass]="{ 'pasuser-header-number': isPASUser }"
  >
    <div class="header-top pt-1 pb-1">
      <div class="row justify-content-center">
        <div class="col-md-5">
          <div class="text-md-end">
            <img class="call-icon" src="assets/images/w2c/call-light.png" />
            <span class="number text-md-end"> +61 2 9225 7599</span>
          </div>
        </div>
        <div class="col-md-7">
          <img
            class="calender-icon"
            src="assets/images/w2c/calendar_light.png"
          />
          <span class="dayntime">
            Monday to Friday, 8am to 9pm AEST – Saturday and Sunday, 9am to 6pm
            AEST</span
          >
        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid shadowonscroll">
    <div class="main-header">
      <div class="row">
        <nav
          class="navbar navbar-expand-lg p-0 bsb-navbar bsb-navbar-hover bsb-navbar-caret"
        >
          <div class="container-xl  p-3">
            <a class="navbar-brand" href="/">
              <img class="mainlogo" src="assets/images/w2c/w2clogo.png" />
            </a>

            <button
              class="navbar-toggler"
              type="button"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasNavbar"
              aria-controls="offcanvasNavbar"
            >
              <span class="navbar-toggler-icon"
                ><img src="assets/images/w2c/menu.png"
              /></span>
            </button>

            <div
              class="offcanvas offcanvas-start align-items-lg-end"
              tabindex="-1"
              id="offcanvasNavbar"
              aria-labelledby="offcanvasNavbarLabel"
            >
              <div class="offcanvas-header border-bottom">
                <h5 class="offcanvas-title" id="offcanvasNavbarLabel">
                  <a class="navbar-brand" href=""
                    ><img class="mainlogo" src="assets/images/w2c/svg-logo.png"
                  /></a>
                </h5>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                ></button>
              </div>
              <div class="offcanvas-body">
                <ul class="navbar-nav">
                  <li class="nav-item dropdown px-2">
                    <a
                      class="nav-link dropdown-toggle"
                      aria-current="page"
                      role="button"
                      routerLink="/travel-insurance"
                      aria-expanded="false"
                      >Travel Insurance</a
                    >
                    <ul class="dropdown-menu">
                      <li>
                        <a
                          class="dropdown-item"
                          routerLink="/family-travel-insurance"
                          >Family Travel Insurance</a
                        >
                      </li>
                      <li>
                        <a
                          class="dropdown-item"
                          routerLink="/travel-medical-insurance"
                          >Travel Medical Insurance</a
                        >
                      </li>
                      <li>
                        <a
                          class="dropdown-item"
                          routerLink="/cruise-travel-insurance"
                          >Cruise Travel Insurance</a
                        >
                      </li>
                    </ul>
                  </li>
                  <li class="nav-item dropdown px-2">
                    <a
                      class="nav-link dropdown-toggle"
                      role="button"
                      routerLink="/whats-covered"
                      aria-expanded="false"
                      >What’s Covered</a
                    >
                    <ul class="dropdown-menu">
                      <li>
                        <a class="dropdown-item" routerLink="/top-cover">Top</a>
                      </li>
                      <li>
                        <a class="dropdown-item" routerLink="/essentials-cover"
                          >Essentials</a
                        >
                      </li>
                      <li>
                        <a class="dropdown-item" routerLink="/basics-cover"
                          >Basics</a
                        >
                      </li>
                      <li>
                        <a class="dropdown-item" routerLink="/domestic-cover"
                          >Domestic</a
                        >
                      </li>
                      <li>
                        <a
                          class="dropdown-item"
                          routerLink="/annual-multi-trip-cover"
                          >Annual Multi-Trip</a
                        >
                      </li>
                      <li>
                        <a class="dropdown-item" routerLink="/ski-cover"
                          >Ski Cover</a
                        >
                      </li>
                      <li>
                        <a class="dropdown-item" routerLink="/existing-medical"
                          >Existing Medical</a
                        >
                      </li>
                    </ul>
                  </li>
                  <li class="nav-item px-2">
                    <a class="nav-link" routerLink="/faqs">FAQs</a>
                  </li>
                  <li class="nav-item px-2">
                    <a class="nav-link" routerLink="/emergency">Emergency</a>
                  </li>
                  <li class="nav-item px-2">
                    <a class="nav-link" routerLink="/how-to-claim"
                      >How to Claim</a
                    >
                  </li>
                  <li class="nav-item px-2">
                    <a class="nav-link" routerLink="/travel-alerts"
                      >Travel Alerts</a
                    >
                  </li>
                </ul>
              </div>
              <!-- Visible only in mobile menu -->
              <div
                class="offcanvas-footer offcanvas-header border-top flex-column text-center"
              >
                <div class="col-12">
                  <a href="tel:+61 2 9225 7599"
                    ><i class="fa-solid fa-phone"></i>
                    <span class="fw-semibold"> +61 2 9225 7599</span></a
                  >
                </div>
                <div class="col-12">
                  <i class="fa-solid fa-business-time"></i>
                  <span class=""
                    >Monday to Friday, 8am to 9pm AEST – Saturday and Sunday,
                    9am to 6pm AEST</span
                  >
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </div>
  </div>
</section>
